import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userChangeAction } from "../../actions/userActions";
import ProfilePic from "../../assets/images/profile_pic.jpeg";
import Bills from "../../assets/images/Bills.png"
import PendingState from "../../assets/images/PendingState.png"
import Done from "../../assets/images/Done.png"
import Amend from "../../assets/images/Amend.png"
import { changeInfoInUserInfo } from "../../actions/userActions";
import {
  getCurrentUserFromCookies,
  getRegionOfUser,
  getUserId,
  getUserSID,
  updateCookiesInfo,
  updateInfoInCurrentUser,
} from "../../utils/helpers";
import { momentFunction } from "../../utils/moment";
import axios from "../../utils/axios";
import Dropdown from "../Dropdown";
import Menu from "../Menu";
import { Roles } from "../../routes/Role.types";
import { CgBell } from "react-icons/cg";
import { TiTick } from "react-icons/ti";
import { BiCheckDouble } from "react-icons/bi";
import "./infoHeader.css"

const InfoHeader = ({ title }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [count, setCount] = useState(1)
  const { userRole } = useSelector((state) => state.userChange);
  const { userInfo } = useSelector((state) => state.userLogin);


  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);


  const userProfileInfo = useSelector(
    (state) => state.userProfileInfo.response
  );

  console.log('infitLoginStateinfofhgh', userProfileInfo)
  const [open, setopenNotify] = useState(false);
  const onClickNotify = () => setopenNotify(!open);

  useEffect(() => {
    getRefreshState();
  }, []);


  // Fetch notifications dynamically based on the user's role and uid
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // setLoading(true); // Uncomment if you handle loading state

        // Make an API call to fetch notifications
        const response = await axios.get(`/notifications/fetch?uid=${getUserId()}`);
        const { data } = response;

        if (data.status === 200 && data.data.length > 0) {
          const notificationsData = data.data;
          setNotifications(notificationsData || []);
          const unreadCount = notificationsData.filter((msg) => !msg.isRead).length;
          setUnreadCount(unreadCount);
        } else {
          console.error("Unexpected API response structure:", data);
          setNotifications([]);
          setUnreadCount(0);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        // setLoading(false); // Uncomment if you handle loading state
      }
    };

    fetchNotifications();
  }, []); // Runs once on component mount



  // Mark notification as read
  const markNotificationAsRead = async (notificationId) => {
    try {
      // API call to mark the notification as read
      await axios.patch(`/notifications/markAsRead/${notificationId}`);

      // Update the state locally to reflect the read status
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, isRead: true } // Mark as read
            : notification
        )
      );

      // Decrease the unread count
      setUnreadCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    } catch (error) {
      // Log error for debugging
      console.error("Error marking notification as read:", error);
    }
  };

  function Notification({ notification }) {
    // Function to calculate local time ago
    const getLocalTimeAgo = (utcTimeString) => {
      if (!utcTimeString) return "Invalid time";

      // Convert the string to proper UTC format
      const utcTime = `${utcTimeString}Z`; // Adding 'Z' for UTC format

      // Get current local time and convert it to UTC
      const now = new Date();
      const utcNow = new Date(now.toISOString()); // Current time in UTC

      // Parse the provided UTC time
      const utcDate = new Date(utcTime);

      // Calculate time difference in milliseconds
      const timeDiff = utcNow.getTime() - utcDate.getTime();

      // Handle negative values (future dates)
      if (timeDiff < 0) return "In the future";

      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (minutes < 60) {
        return `${minutes} minutes ago`;
      } else if (hours < 24) {
        return `${hours} hours ago`;
      } else {
        return `${days} days ago`;
      }
    };




    return (
      <p style={{ fontSize: "18px" }}>
        {getLocalTimeAgo(notification.timeAgo)}
      </p>
    );
  }

  // Mark all notifications as read
  const markAllAsRead = async () => {
    try {
      // API call to mark all notifications as read
      const userId = getUserId();
      await axios.patch(`/notifications/markAllAsRead/${userId}`);

      // Update state locally
      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, isRead: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };



  const getRefreshState = (OptionalChange = null) => {
    axios
      .get(`/services/status/${getUserSID()}`)
      .then((res) => {
        if (res.data.data.code === 200) {
          const { authClio, authIntuit, updated_at } = res.data.data.body;
          dispatch(
            changeInfoInUserInfo({
              updated_at: updated_at,
              authClio: authClio,
              authIntuit: authIntuit,
              ...OptionalChange,
            })
          );
          if (authClio !== undefined) {
            Cookies.set("authClio", authClio);
          } else {
            Cookies.set("authClio", false);
          }

          if (authIntuit !== undefined) {
            Cookies.set("authIntuit", authIntuit);
          } else {
            Cookies.set("authIntuit", false);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const curClientList = userInfo.role;
  console.log("curClientListGG", curClientList);
  const menuList = [
    {
      option: "Profile",
      link: "/profile/edit",
    },
    {
      option: "divider",
      link: "",
    },
  ];

  const onClientChange = (e, list, type) => {
    const text = e.target.innerText;
    const role = e.target.dataset.role;
    const filter = list.filter((e) => {
      return e.display_firmname.trim() === text && e.role.trim() === role;
    });

    if (
      filter[0].display_firmname !== userRole.display_firmname ||
      filter[0].role !== userRole.role
    ) {
      setShowLoading(true);
      setTimeout(() => {
        setShowLoading(false);
        window.location.reload();
      }, 3000);
    }

    return filter[0];
  };

  const pageTitle = () => {
    switch (location.pathname) {
      case "/runreport":
        return "Law society compliance reports";
      case "/tasks/form":
        return "Tasks";
      default:
        break;
    }
  };


  return (
    <>
      {showLoading && <div className="loader">Changing Client</div>}
      <header className="mainHeader">
        <span className="title">
          {title}
          <div>
            <text>{pageTitle()}</text>
          </div>
          {["/runreport", "/reports", "/new_report_history"].includes(location.pathname) && (
            <text>
              Clio & QuickBooks were last refreshed on:{" "}
              <span>
                {userInfo.updated_at
                  ? momentFunction.formatDate(
                    userInfo.updated_at,
                    "MMM D, YYYY hh:mm A"
                  )
                  : "Sep 13, 2023 08:40 AM"}
              </span>
            </text>
          )}
        </span>
        <div className="controls">
          <Dropdown

            type={getCurrentUserFromCookies().role == Roles.SUPERADMIN ? 'CloudAct' : 'Firmname'}
            addClassName={"bg-transparent"}
            list={curClientList}
            curClient={
              getCurrentUserFromCookies() &&
              getCurrentUserFromCookies().display_firmname
            }
            handleClientChange={(e, list, type) =>
              onClientChange(e, list, type)
            }
            stateToChange={(e) => dispatch(userChangeAction(e))}
          ></Dropdown>

          {/* hide for now .  uncomment when you need to use notification part */}

          {/* <div class="dropdown notificationDrop unread">
            <button
              onClick={onClickNotify}
              className={`${open ? "show" : ""}`}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M6 19V10C6 8.4087 6.63214 6.88258 7.75736 5.75736C8.88258 4.63214 10.4087 4 12 4C13.5913 4 15.1174 4.63214 16.2426 5.75736C17.3679 6.88258 18 8.4087 18 10V19M6 19H18M6 19H4M18 19H20M11 22H13"
                  stroke="#171D34"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />{" "}
                <path
                  d="M12 4C12.5523 4 13 3.55228 13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3C11 3.55228 11.4477 4 12 4Z"
                  stroke="#171D34"
                  stroke-width="1.5"
                />{" "}
              </svg>
            </button>
            <div className={`notifications ${open ? "show" : ""}`}>
              <span className="title">
                Notification <span className="count">2</span>
              </span>
              <div className="notifcationInner">
                <a href="#" className="unread">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                  </span>
                </a>
                <a href="#" className="unread">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
                <a href="#">
                  <span className="thumb">
                    <img
                      src="https://portal.bilardo.gov.tr/assets/pages/media/profile/profile_user.jpg"
                      alt="unknown"
                    />
                  </span>
                  <span className="nameInfo">
                    <strong>{`${
                      userProfileInfo?.first_name != undefined
                        ? userProfileInfo?.first_name
                        : ""
                    } ${
                      userProfileInfo?.last_name != undefined
                        ? userProfileInfo?.last_name
                        : ""
                    }`}</strong>
                    5m ago
                  </span>
                  <span className="notifyInfo">
                    <span>
                      assigned a <strong>Report</strong>
                    </span>
                    <span className="blueColor">
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M1.08398 4.525L2.9174 6.625L7.50065 1.375"
                          stroke="#307FF4"
                          stroke-width="0.875"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />{" "}
                      </svg>{" "}
                      read
                    </span>
                  </span>
                </a>
              </div>
              <a className="allRead" href="#">
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    d="M1 6.9L4.143 10.5L12 1.5M17 1.563L8.428 10.563L8 10"
                    stroke="#307FF4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />{" "}
                </svg>{" "}
                Mark all as read
              </a>
            </div>
          </div> */}

          {/* {userRole.company_profile_pic && (<img alt="Company Profile" src={userRole.company_profile_pic}/>)} */}


          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style={{
                border: "none",
                top: "100px",
                marginLeft: "12px",
                borderRight: "2px solid rgb(225, 240, 253)",
                borderRadius: "0px",
              }}
            >
              <CgBell
                style={{
                  position: "relative",
                  padding: "0px 6px 0px 6px",
                  fontSize: "46px",
                  background: "#212529",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "50%",
                  border: "none",
                  outline: "none",
                }}
              />
              {unreadCount > 0 && <span className="dot"></span>}
            </button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              style={{
                width: "427px",
                padding: "6px 0px",
                borderRadius: "12px",
                maxHeight: "750px", // Set maximum height
                overflowY: "auto", // Enable vertical scrolling
                overflowX: "no-scroll"
              }}
            >
              <li>
                <div className="modal-header" style={{ padding: "24px 32px" }}>
                  <div className="heading">Notification</div>
                  <div className="note-counts">{unreadCount}</div>
                </div>
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="modal-content"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "22px",
                        background: "#F0F8FF",
                        padding: "19px 47px 3px 23px",
                        borderBottom: "2px solid #73c3fd",
                      }}
                    >
                      <div className="modal-img">
                        {notification.type === "Billing" && (
                          <img
                            src={
                              notification.status === "NOT_STARTED"
                                ? Bills
                                : notification.status === "PENDING"
                                  ? PendingState
                                  : notification.status === "AMEND"
                                    ? Amend
                                    : Done
                            }
                            alt="Profile"
                            style={{
                              width: "62px",
                              height: "60px",
                              // borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>

                      <div className="modal-area">
                        <p style={{ fontSize: "20px" }}>{notification.message}</p>
                        <div className="modal-info">
                          <Notification notification={notification} />
                          {!notification.isRead && (
                            <p style={{ color: "#73c3fd", marginRight: "-14px" }}>
                              <span onClick={() => markNotificationAsRead(notification.id)}>
                                <TiTick fontSize={20} />
                              </span>
                              read
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "16px",
                      fontSize: "18px",
                      color: "#999",
                    }}
                  >
                    No notifications available.
                  </div>
                )}
                {notifications.length > 0 && (
                  <div
                    className="mark-section"
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      fontSize: "18px",
                      color: "#73c3fd",
                      cursor: "pointer",
                    }}
                    onClick={markAllAsRead}
                  >
                    <span>
                      <BiCheckDouble fontSize={24} />
                    </span>
                    Mark all as read
                  </div>
                )}
              </li>
            </ul>
          </div>


          <div

            onClick={
              getCurrentUserFromCookies().role === Roles.SUPERADMIN
                ? undefined // Disables the onClick if the user is SUPERADMIN
                : (e) => {
                  setOpenMenu((state) => !state);
                }
            }

            className="dropdown profile"
          >
            <button className="dropdownBtn">
              <img src={userInfo.profile_pic || ProfilePic} alt="unknown" />
              <span>
                <strong>
                  {" "}
                  {`${userProfileInfo?.first_name != undefined
                    ? userProfileInfo?.first_name
                    : ""
                    } ${userProfileInfo?.last_name != undefined
                      ? userProfileInfo?.last_name
                      : ""
                    }`}
                </strong>
                {userRole.role === "ADMIN" ? "Administrator" : userRole.role}
              </span>
            </button>
            <Menu open={openMenu} menuList={menuList} />
          </div>
        </div>
      </header>
    </>
  );
};



export default InfoHeader;
