import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { Rnd } from "react-rnd";
import Select from "react-select";
import Layout from "../../components/LayoutComponents/Layout";
import { useSelector } from "react-redux";
import "react-resizable/css/styles.css";
import "../../components/FormPages/forms/App.css";
import ModernToolbar from "../../components/FormPages/forms/newComponents/ModernToolbar";
import axios from "../../utils/axios";
import { toast } from "react-hot-toast";

const FormMapper = () => {
  const [fields, setFields] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [selectedFields, setSelectedFields] = useState({});
  const [options, setOptions] = useState({});
  const [concatenatedValue, setConcatenatedValue] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [data, setData] = useState(null);
  const { response } = useSelector((state) => state.userProfileInfo);
  const [loading, setLoading] = useState(false); // Track loading state

  const fetchFormPdf = async (formName) => {
    try {
      const response = await axios.get(`/fetch-pdf?fileName=${formName}.pdf`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  };

  const fetchFormJson = async (jsonName) => {
    try {
      const response = await axios.get(`/fetch-json?fileName=${jsonName}.json`);
      setJsonData(response.data.staticFields);
    } catch (error) {
      console.error("Error fetching the JSON:", error);
    }
  };

  useEffect(() => {
    if (selectedForm) {
      setLoading(true);
      Promise.all([
        fetchFormPdf(selectedForm),
        fetchFormJson(selectedForm),
        fetch(`/documents/data.json`).then((response) => response.json()),
      ])
        .then(([_, __, data]) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error loading form data:", error);
          setLoading(false);
        });
    }
  }, [selectedForm]);

  useEffect(() => {
    const generateOptions = (obj) => {
      let categories = {};

      const processValue = (value, path) => {
        if (Array.isArray(value)) {
          return value.flatMap((item, index) =>
            processValue(item, `${path}[${index}]`)
          );
        } else if (typeof value === "object" && value !== null) {
          return Object.entries(value).flatMap(([key, val]) =>
            processValue(val, path ? `${path}.${key}` : key)
          );
        } else {
          return [
            {
              label: path,
              value: path,
              type: "simple",
              simpleValue: value,
            },
          ];
        }
      };

      Object.entries(obj).forEach(([categoryKey, categoryData]) => {
        categories[categoryKey] = processValue(categoryData, categoryKey);
      });

      return categories;
    };

    if (data) {
      setOptions(generateOptions(data));
    }
  }, [data]);

  useEffect(() => {
    if (jsonData) {
      setFields(jsonData);
      localStorage.setItem("jsonData", JSON.stringify(jsonData));
      localStorage.setItem("fields", JSON.stringify(jsonData));
    }
  }, [jsonData]);

  useEffect(() => {
    localStorage.setItem("fields", JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    const newValue = concatenateObjectValues(selectedFields);
    setConcatenatedValue(newValue);
  }, [selectedFields]);

  const handleChange = (category, selectedOptions) => {
    setSelectedFields((prevSelectedFields) => ({
      ...prevSelectedFields,
      [category]: selectedOptions,
    }));
  };

  function concatenateObjectValues(obj) {
    return Object.entries(obj)
      .flatMap(([category, options]) => options.map((option) => option.value))
      .join(", ");
  }

  const handleFieldSelection = (event, field) => {
    setFields((prevFields) =>
      prevFields.map((f) => ({
        ...f,
        selected: f.id === field.id,
      }))
    );
  };

  const handleEditField = (id, value) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, bind: value } : field
      )
    );
  };

  const handleEditCheckbox = (id, value) => {
    console.log(value);
    let newValue = value;
    if (value === true || value === "true") {
      newValue = "checked";
    } else {
      newValue = "unchecked";
    }
    console.log("newValue", newValue);
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, bind: newValue } : field
      )
    );
  };

  const handleSaveJson = async () => {
    if (!pdfUrl || !fields) return;

    try {
      const dataToSave = fields;
      const formName = selectedForm.replace(".pdf", "");

      const response = await axios.post("/upload-json", {
        file_name: formName,
        data: { staticFields: dataToSave },
      });

      if (response.data.success) {
        toast.success("JSON saved to AWS S3 successfully!");
      } else {
        toast.error("Failed to save JSON to AWS S3.");
      }
    } catch (error) {
      toast.error("Error saving JSON to AWS S3.");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const clearAllSelections = () => {
    setSelectedFields({});
  };

  const handleMapToField = () => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.selected ? { ...field, bind: concatenatedValue } : field
      )
    );
  };

  const getFieldHighlight = (field) => {
    if (field.selected) {
      return "rgba(0, 123, 255, 0.5)";
    }

    return "transparent";
  };

  return (
    <Layout title={`Welcome ${response.first_name} ${response.last_name}`}>
      <div>
        {loading && <div className="loader">Loading...</div>}
        <div className="form-dropdowns-inline">
          <div className="dropdown-container">
            <h3 className="mapping-dropdown-heading">Select Form (PDF):</h3>
            <Select
              options={[
                { value: "Form00", label: "Form 00" },
                { value: "Form4", label: "Form 4" },
                { value: "Form6A", label: "Form 6A" },
                { value: "Form12", label: "Form 12" },
                { value: "Form14D", label: "Form 14D" },
                { value: "Form17", label: "Form 17" },
                { value: "Form6", label: "Form 6" },
              ]}
              value={
                selectedForm
                  ? {
                      value: selectedForm,
                      label: selectedForm.replace(".pdf", ""),
                    }
                  : null
              }
              onChange={(option) => setSelectedForm(option?.value || "")}
              className="basic-single mapping-dropdown-drop"
              classNamePrefix="select"
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          </div>
        </div>

        <div className="fill-information-page panel trans">
          <div className="pBody">
            <div className="row">
              <div className={"col-md-9"}>
                <div className="page-content">
                  <div className="head d-flex justify-content-between align-items-right">
                    <div style={{ marginLeft: "auto" }}>
                      <button
                        className="btn btnPrimary"
                        onClick={handleSaveJson}
                      >
                        Save JSON
                      </button>
                    </div>
                  </div>
                  <div className="body" style={{ backgroundColor: "#e3e3e3" }}>
                    <div id="pdf-content">
                      <div className="toolbar">
                        <ModernToolbar
                          currentPage={currentPage}
                          numPages={numPages}
                          setCurrentPage={setCurrentPage}
                          setScale={setScale}
                          scale={scale}
                        />
                      </div>
                      <div
                        id="pdfContainer"
                        style={{ flex: 1, position: "relative" }}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <Document
                          file={pdfUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            className="pdf-canvas"
                            pageNumber={currentPage}
                            scale={scale}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          >
                            {fields
                              .filter((field) => field.page === currentPage)
                              .map((field) => (
                                <Rnd
                                  key={field.id}
                                  size={{
                                    width: field.width,
                                    height: field.height,
                                  }}
                                  position={{
                                    x: field.x * scale,
                                    y: field.y * scale,
                                  }}
                                  bounds="parent"
                                  disableDragging={true}
                                  enableResizing={false}
                                  style={{
                                    border: "1px solid #ff9000",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "none",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleFieldSelection(event, field);
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      height: "100%",
                                      fontSize: field.fontSize,
                                      padding: "0",
                                      backgroundColor: getFieldHighlight(field),
                                    }}
                                  >
                                    {(field.type === "TextField" ||
                                      field.type === "TextArea") && (
                                      <textarea
                                        value={field.bind || ""}
                                        onChange={(e) =>
                                          handleEditField(
                                            field.id,
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "12px",
                                          border: "none",
                                          backgroundColor: "transparent",
                                          resize: "none",
                                        }}
                                      />
                                    )}
                                    {field.type === "CheckBox" && (
                                      <input
                                        id={field.id}
                                        type="checkbox"
                                        checked={field.bind === "checked"}
                                        onChange={(e) =>
                                          handleEditCheckbox(
                                            field.id,
                                            e.target.checked.toString()
                                          )
                                        }
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          cursor: "pointer",
                                          marginRight: "5px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </Rnd>
                              ))}
                          </Page>
                        </Document>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="page-sidebar">
                  <div className="head">Matter Data Mappings</div>
                  <div className="body">
                    <div className="content">
                      <h3 className="mapping-dropdown-heading">
                        Concatenated Value:
                      </h3>
                      <textarea
                        value={concatenatedValue}
                        readOnly
                        style={{
                          width: "100%",
                          minHeight: "100px",
                          marginBottom: "10px",
                        }}
                      />
                      <div className="button-container">
                        <button
                          className="btn btnPrimary"
                          onClick={handleMapToField}
                        >
                          Map Field
                        </button>
                        <button
                          className="btn btnPrimary"
                          onClick={clearAllSelections}
                        >
                          Clear Mapping
                        </button>
                      </div>
                      {Object.keys(options).map((category) => (
                        <div key={category}>
                          <h3 className="mapping-dropdown">{category}</h3>
                          <Select
                            isMulti
                            options={options[category].map(
                              ({ label, value }) => ({ label, value })
                            )}
                            value={selectedFields[category] || []}
                            onChange={(selectedOptions) =>
                              handleChange(category, selectedOptions)
                            }
                            className="basic-multi-select mapping-dropdown-drop"
                            classNamePrefix="select"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FormMapper;
